@import url("https://fonts.googleapis.com/css2?family=Preahvihear&display=swap");

body {
  background-repeat: no-repeat;
  background-color: rgb(18, 18, 18);
  user-select: none;
  color: rgb(241, 241, 241);
  width: 100%;
  height: 100%; 
  background-size: 200px;
  align-items: center;
  text-align: center;
  justify-content: center;
  overflow: hidden;
  font-family: "Preahvihear", sans-serif, serif;
  transition: all 0.3s ease;
}

.app {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

header {
  top: 0;
}

#clickarea {
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.title {
  margin-top: 25px;
  font-size: 50px;
}

.number {
  transition: opacity 0.5s ease-in-out;
  font-size: 36px;
  margin-top: -35px;
}

.number.pp {
  animation: popDown 1s ease-in-out;
}

#pop-image {
  justify-content: center;
  text-align: center;
  align-items: center;
  display: block;
}

.image {
  display: block;
  margin-top: -25px;
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.np {
  background-image: url("https://api.cutebear.in.th/v1/image?name=popbear-idle");
   /*background-image: url("./no.jpg");*/
}

.p {
  background-image: url("https://api.cutebear.in.th/v1/image?name=popbear-onclick");
  /*background-image: url("./yes.jpg");*/
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.counter {
  font-size: 50px;
  display: flex;
  float: right;
  margin-right: 55px;
  margin-top: -55px;
}

#bottomarea {
  margin-top: 15px;
  bottom: 0;
  overflow: hidden;
}

.bottom-items {
  display: flex;
  justify-content: space-between;
}

.left-items {
  list-style: none;
  margin-top: 0;
  margin-left: 15px;
}

.right-items {
  list-style: none;
  margin-top: 0;
  margin-right: 15px;
}

.right-items button {
  width: 70px;
  text-align: center;
  background-color: rgb(255, 255, 255);
  border-radius: 50px;
  color: black;
  font-family: "Preahvihear", sans-serif, serif;
  margin-left: 10px;
  padding: 15px 0;
  cursor: pointer;
  font-size: 18px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  transition: all 0.3s ease;
}

.right-items button:hover {
  opacity: 0.65;
}

footer {
  background-color: rgb(10, 10, 10);
  overflow: hidden;
  width: 100%;
  height: 30px;
  justify-content: left;
  text-align: left;
  position: absolute;
  bottom: 0;
}

.footer-items {
  display: flex;
  justify-content: space-between;
}

.copyright-brand {
  margin-left: 15px;
  display: block;
}

.inner-link {
  color: rgb(255, 40, 105);
  transition: all 0.3s ease;
}

.inner-link:hover {
  color: rgb(170, 21, 66);
}

.footer-links {
  display: inline-block;
  list-style: inline;
  margin-top: 0;
  margin-right: 15px;
}

.footer-links li {
  margin: 0px 5px;
  display: inline-block;
  text-align: center;
}

.footer-links li a {
  text-decoration: none;
  color: #d8d8d8;
  text-decoration: none;
  font-size: 16px;
  position: relative;
  transition: all 0.3s ease;
}

.footer-links li a::after {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 10px;
  transform-origin: 0;
  background-color: rgb(255, 40, 105);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.footer-links li a:hover::after {
  transform: scaleX(1);
}

.footer-links li a:hover {
  color: rgb(255, 40, 105);
}

@keyframes popDown {
  0% {
    transform: scale(1.5) rotate(-0.015turn);
  }
  50% {
    transform: scale(1.25) rotate(-0.01turn);
  }
  100% {
    transform: scale(1) rotate(0turn);
  }
}

@media (max-width: 760px) {
  footer {
    background-color: rgb(10, 10, 10);
    display: block;
    overflow: hidden;
    width: 100%;
    height: 60px;
    justify-content: left;
    text-align: left;
    position: absolute;
    bottom: 0;
  }
  .footer-items {
    display: block;
    justify-content: center;
  }

  .copyright-brand {
    margin-left: 0;
    text-align: center;
    display: block;
  }

  .inner-link {
    color: rgb(255, 40, 105);
  }

  .footer-links {
    display: inline-block;
    width: 100%;
    margin-left: -40px;
    text-align: center;
    list-style: inline;
  }

  .footer-links li {
    margin: 0px 5px;
    display: inline-block;
    text-align: center;
  }

  .footer-links li a {
    text-decoration: none;
    color: #d8d8d8;
    text-decoration: none;
    font-size: 16px;
    position: relative;
    transition: all 0.3s ease;
  }
}
